<template>
  <header>
    <div class="header-holder">
      <div class="menu-button">
        <a href="javascript:void(0);" class="show-menu-btn">
          <span></span>
          <span></span>
          <span></span>
        </a>
      </div>
      <div class="header-info" v-if="s_info" v-html="s_info">

      </div>
      <div class="logo-holder">
        <router-link :to="{name: 'home'}">
          <img :src="logo">
        </router-link>
      </div>

      <div class="header-info right-header-info" v-if="phones">
        <template v-for="(value,key) in phones " :key="key" >
          <a :href="'tel:'+value.real" v-html="value.text" v-if="value.isNumber">

          </a>
          <p v-else v-html="value.text">
          </p>
        </template>
      </div>
      <div class="lang-panel">
        <div class="dropdown show" v-if="lang">
          <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown">
            <img :src="current.icon" alt="">
            {{current.short}}
          </a>
          <div class="dropdown-menu" x-placement="bottom-end">
            <a v-for="(l,index) in lang" :key="index" class="dropdown-item active "
               href="javascript:void(0)" @click="setLang(l)"
               :class="{'active': l.text === current.text}">
              <img :src="l.icon" alt="">
              {{l.short}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  export default {
    name    : 'MenuHeader',
    mounted() {

    },
    methods : {
      setLang(lang) {
        this.current = lang;
        this.fetchLang();
      },
      fetchLang() {
        fetch(this.current.value).then(response => response.json()).then(data => {
          if (data) {
            localStorage.setItem('$lang', this.current.short);
            this.$load(data, this.current.short)
          }
        })
      },
      fetchOrigin() {
        return fetch('/storage/i18n/original.json').then(response => response.json()).then(data => {
          if (data) {
            this.$load(data, this.current.short)
          }
        })
      }
    },
    data() {
      return {
        init   : false,
        current: {
          icon : '/storage/i18n/ua.svg',
          title: 'Україньска',
          short: 'UA',
          value: '/storage/i18n/original.json'
        }
      }
    },
    computed: {
      settings() {
        return this.$store.getters.getSettings
      },
      lang() {
        return this.settings ? this.settings.lang : this.settings
      },
      logo() {
        return this.settings && this.settings.logo ? this.settings.logo : ''
      },
      s_info() {
        return this.settings && this.settings.s_info ? this.settings.s_info : false
      },
      phones() {
        return this.settings && this.settings.phone ?
          this.settings.phone.split(',').map(x => x.trim())
            .map(x => {return {real: x.replace(/^\D+/g, ''), text: x, isNumber: x.startsWith("+")}}) : false
      },
    },
    watch   : {
      lang(val) {
        if (val) {
          this.fetchOrigin().then(() => {
            const lang = localStorage.getItem('$lang');
            if (lang) {
              let x = val.find(i => i.short === lang);
              if (x) {
                this.current = x;
                this.fetchLang()
              }
            }
          })
        }
      },
      settings(val) {
        if (val && val.favicon) {
          let link = document.querySelector('link[rel~=\'icon\']');
          if (link) {
            link.href = val.favicon
          }
        }
      }
    }
  }
</script>