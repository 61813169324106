<template>
  <div class="main-wrapper wrapper">
    <div class="home-header custom-header" v-if="home && home.settings">
      <div class="header-content-alt bg-cover" v-if="home.settings.image" :style="headBackground">
        <router-link :to="{name: 'catalog', params:{ category: home.settings.url}}" style="display: block"></router-link>
      </div>
      <div class="header-content" v-else-if="home.settings.header || home.settings.subtitle">
        <h1 v-html="home.settings.header"></h1>
        <div class="subtitle" v-html="home.settings.subtitle"></div>
        <div class="button-holder" v-if="home.settings.button && home.settings.url">
          <router-link :to="{name: 'catalog', params:{ category: home.settings.url}}" class="default-btn ">
            <span class="btn-text" style="margin-right: 0">{{$static('home.to_catalog', 'До каталогу')}}</span>

          </router-link>
        </div>
      </div>
    </div>

    <div class="sorting-panel" v-if="tags && tags.length">
      <h2>{{$static('home.for_all', 'Товари для кожного')}}</h2>
      <div class="sort-list">
        <div class="sorting-checkbox">
          <input id="all" type="checkbox" v-model="all_tags" value="1">
          <label for="all">
            <span class="text">{{$static('options.all', 'Усі')}}</span>
          </label>
        </div>

        <div class="sorting-checkbox" v-for="(op, index) in tags" :key="index">
          <input :id="op.id" type="checkbox" v-model="optionSelect" :value="op.id">
          <label :for="op.id">
            <!--            <span class="pick" style="background-image: url('')" ></span>-->
            <span class="pick" v-if="op.image" :style="getImage(op)"></span>
            <span class="pick" v-else-if="op.color" :style="getColor(op)"></span>
            <span class="text">{{$dynamic(op, op.name, 'name')}}</span>
          </label>
        </div>
      </div>
    </div>
    <div class="mainstream-products" v-if="items">
      <ul class="catalog-grid" @mousedown="e => mouseDownHandlerTarget(e,'catalog-grid')">
        <li class="catalog-item" v-for="(item,index) in items" :key="index">
          <div class="item-holder">
            <div class="product-label label-new" v-if="isHot(item)">
              <span class="label-text">New</span>
            </div>
            <div class="product-label label-sale" v-if="item.price_old">
              <div class="tile_label tile_sale">-{{getDiscount(item)}}%</div>
            </div>
            <router-link :to="{name: 'Item', params:{ category: categoryURL(item), id: item.id}}" class="image bg-cover"
                         :style="`background-image: url(${getItemImage(item)});`"></router-link>
            <div class="item-content">
              <div class="product-name">
                <router-link :to="{name: 'Item', params:{ category: categoryURL(item), id: item.id}}" >{{$dynamic(item, item.title, 'title')}}</router-link>
              </div>
            </div>
            <div class="options">
              <div class="price-box">
                <div class="price-element" v-if="item.price"> {{$filters.money(item.price)}} {{$store.getters.getSettings.symbol}}</div>
                <div class="price-old" v-if="item.price_old"> {{$filters.money(item.price_old)}} {{$store.getters.getSettings.symbol}}</div>
              </div>
              <div class="right-options">
                <router-link :to="{name: 'Item', params:{ category:  categoryURL(item), id: item.id}}" class="default-btn">
                  <span class="btn-text">{{$static('catalog.info', 'Детальніше')}}</span>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.5 10L12.8536 9.64645L13.2071 10L12.8536 10.3536L12.5 10ZM7.85355 4.64645L12.8536 9.64645L12.1464 10.3536L7.14645 5.35355L7.85355 4.64645ZM12.8536 10.3536L7.85355 15.3536L7.14645 14.6464L12.1464 9.64645L12.8536 10.3536Z"
                        fill="white"/>
                  </svg>
                </router-link>
              </div>
            </div>
          </div>
        </li>
        <li class="catalog-item more-catalog-item" @click="getMore" v-if="more">
          <div class="item-holder d-flex align-items-center text-center justify-content-center h-100">
            <div class="more-content">
              <div class="text-wrapper">
                {{$static('catalog.more_load', 'Завантажити ще')}}

              </div>
              <div class="icons-holder">
                <img src="/images/icons/load.svg" >
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="link-holder " v-if="home && home.settings && home.settings.url">
        <router-link :to="{name: 'catalog', params:{ category: home.settings.url}}" class="default-btn btn-outline large-btn">
          <span class="text">{{$static('home.back_catalog', 'Перейти до каталогу')}}</span>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5 10L12.8536 9.64645L13.2071 10L12.8536 10.3536L12.5 10ZM7.85355 4.64645L12.8536 9.64645L12.1464 10.3536L7.14645 5.35355L7.85355 4.64645ZM12.8536 10.3536L7.85355 15.3536L7.14645 14.6464L12.1464 9.64645L12.8536 10.3536Z"
                fill="black"/>
          </svg>
        </router-link>
      </div>
    </div>
    <div class="custom-home-text" v-if="home && home.template" v-html="home.template">
    </div>

  </div>

</template>

<script>
  import axios from 'axios'
  import { nextTick } from 'vue'

  export default {
    name      : 'Home',
    data() {
      return {
        items  : [],
        page   : 1,
        perPage: 11,
        more   : true,

        optionSelect: [],
        swiperReview: undefined,
        swiperMain  : undefined,
        home        : undefined
      }
    },
    computed  : {
      settings() {
        return this.$store.getters.getSettings
      },
      // landing() {
      //   return this.$store.getters.landing
      // },
      lang() {
        return this.$lang()
      },
      headBackground() {
        return `background-image: url(${this.home.settings.image});`
      },
      logoSrc() {
        return this.settings && this.settings.logo ? this.settings.logo : '/images/logo.svg'
      },
      title() {
        return this.settings && this.settings.title ? this.settings.title : undefined
      },
      subtitle() {
        return this.settings && this.settings.subtitle ? this.settings.subtitle : undefined
      },

      tags() {
        return this.$store.getters.getTags
      },
      all_tags: {
        get() {
          return this.optionSelect.length === 0
        },
        set() {
          this.optionSelect = []
        }
      }
    },
    methods   : {
      getMore() {
        this.page += 1;
        this.getItems()
      },
      fancyClick(e) {
        if (window.change) {
          e.preventDefault();
          e.stopPropagation();
        }
      },
      mouseDownHandlerTarget(e, targetClass) {
        let target = e.target;
        while (!target.className.includes(targetClass)) {
          target = target.parentElement
        }
        window.ele = target;
        window.mouseDownHandler(e)
      },
      getImage(item) {
        return `background-image: url(${item.image});`
      },
      getColor(item) {
        return `background-color: ${item.color};`
      },
      getDiscount(item) {
        return item.preview ? (1 - Math.max(item.price / item.price_old, 0)) * 100 | 0 : ''
      },
      isHot(item) {
        return item.advanced && item.advanced.hot
      },
      getItems(clear) {
        if (this.load) {
          return
        }
        this.load  = true;
        let filter = {priority: true, perPage: this.perPage, page: this.page};
        if (this.optionSelect.length) {
          filter.tags = this.optionSelect.map(id => this.tags.find(o => o.id === id)).map(x => x ? x.id : x);
        }
        axios.post('/public/item/filter', {filter: filter}).then(response => response.data).then(data => {
          this.load = false;
          if (clear) {
            this.items = data.result
          } else {
            this.items.push(...data.result);
          }
          this.page  = data.page;
          this.total = data.total;
          if (this.items.length === this.total) {
            this.more = false
          }
        }).catch(() => {
          this.load = false;
        })
      },
      getItemImage(item) {
        if (item.preview && item.preview.includes("https://")){
          return item.preview
        }
        return item.preview ? `${window.location.protocol}//${window.location.host}${item.preview}` : ''
      },
      categoryObjectID(item) {
        // return {id: 1}
        // console.log(this.$store.getters.getCategories, item.category);
        let result = this.$store.getters.getCategories.find(i => i.id == item.category);
        // if (!result) {
        //
        // }
        return result
      },
      categoryURL(item) {
        // return {id: 1}
        // console.log(this.$store.getters.getCategories, item.category);
        let result = this.$store.getters.getCategories.find(i => i.id == item.category);
        if (!result) {
          return 'default'
        }
        return result.info.url
      }
      // categoryObject() {
      //   return this.$store.getters.getCategories.find(i => i.id === this.categoryId)
      // },
      // categoryName() {
      //   return this.categoryObject ? this.categoryObject.name : ''
      // }
    },
    components: {},
    mounted() {
      fetch('/public/template/home').then(response => response.json()).then(data => {
        this.home = data
      }).then(() => {
        if (this.home && this.home.settings) {
          let title                                 = this.home.settings.meta_title ? this.home.settings.meta_title : window.location.hostname;
          document.querySelector('title').innerText = title;
          document.querySelector('meta[property="og:title"]').setAttribute('content', title);

          let description = this.home.settings.meta_description;
          document.querySelector('meta[name="description"]').setAttribute('content', description ? description : '');
          document.querySelector('meta[property="og:description"]').setAttribute('content', description ? description : '');

          let image = this.home.settings.image ? this.home.settings.image : '';
          document.querySelector('meta[property="og:image"]').setAttribute('content', image);
        }
      });
      this.getItems(true);
    },
    watch     : {
      optionSelect() {
        this.getItems(true)
      }
    }
  }
</script>
