import { createStore } from 'vuex'

let saveBusked = (item) => {
  window.localStorage.setItem('_BUSKED', JSON.stringify(item))
};

let busked = [];
(() => {
  let StartBUSKED = window.localStorage.getItem('_BUSKED');
  if (StartBUSKED) {
    busked = JSON.parse(StartBUSKED)
    if (busked.length > 0) {
      busked = busked.map(i => {
        if (i.additionSelect && i.additionSelect.length) {
          i.additionSelect = i.additionSelect.filter(x => x)
        }
        return i
      })
    }
  }
  window.busked = busked
})();

export default createStore(
  {
    state    : {
      categories     : [],
      settings       : [],
      tags           : [],
      options        : {},
      optionsSettings: {},
      addition       : [],
      busked         : busked,
      deliveryOptions: [],
      payment        : [],
      landing        : undefined
      
    },
    getters  : {
      getCategories     : (state) => state.categories,
      getSettings       : (state) => state.settings,
      getOptions        : (state) => state.options,
      getOptionSettings : (state) => state.optionsSettings,
      getTags           : (state) => state.tags.sort((a, b) => a.order - b.order),
      getAddition       : (state) => state.addition,
      getBusked         : (state) => state.busked,
      getDeliveryOptions: (state) => state.deliveryOptions,
      getPayment        : (state) => state.payment,
      landing           : (state) => state.landing
    },
    mutations: {
      setCategories     : (state, payload) => state.categories = payload,
      setSettings       : (state, payload) => state.settings = payload,
      setTags           : (state, payload) => state.tags = payload,
      setAddition       : (state, payload) => state.addition = payload,
      setOptions        : (state, payload) => {
        state.options[payload.option_id] = payload
      },
      setOptionSettings : (state, payload) => {
        state.optionsSettings = payload
      },
      setDeliveryOptions: (state, payload) => state.deliveryOptions = payload,
      setPayment        : (state, payload) => state.payment = payload,
      setLanding        : (state, payload) => state.landing = payload,
      addToBasked       : (state, payload) => {
        state.busked.push(payload);
        saveBusked(state.busked);
      },
      replaceBuskedIndex: (state, payload) => {
        if (payload.index > -1 && state.busked.length > payload.index) {
          state.busked[payload.index] = payload.value
        }
        saveBusked(state.busked);
      },
      
      delBasked  : (state, payload) => {
        state.busked.splice(payload, 1);
        saveBusked(state.busked);
      },
      clearBasked: (state) => {
        state.busked = [];
        saveBusked(state.busked);
      }
    },
    actions  : {},
    modules  : {}
  });
