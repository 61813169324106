<template>
  <main>
    <div class="overlay-menu">
      <a class="close-menu-btn" href="javascript:void(0);">
        <img src="/images/icons/close.svg">
      </a>
      <div class="line"></div>
      <div class="overlay-menu-wrapper">
        <div class="menu-list">
          <ul>
            <li>
              <router-link :to="{name:'home'}">
                <div class="logo">
                  <img :src="logo">
                </div>
              </router-link>
            </li>

            <li>
              <router-link :to="{name:'about'}">
                <span class="text">{{$static('menu.about', 'Про нас')}}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{name:'delivery'}">
                <span class="text">{{$static('menu.delivery', 'Доставка та оплата')}}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{name:'policy'}">
                <span class="text">{{$static('menu.policy', 'Політика конфіденційності')}}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{name:'dostup'}">
                <span class="text">{{$static('menu.dostup', 'Політика доступності')}}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{name:'offerta'}">
                <span class="text">{{$static('menu.offerta', 'Публічна оферта')}}</span>
              </router-link>
            </li>
          </ul>
          <div class="menu-info time-info" v-if="s_info" v-html="s_info">

          </div>
          <div class="menu-info phone-info" v-if="phones">
            <template v-for="(value,key) in phones " :key="key">
              <a :href="'tel:'+value.real" v-html="value.text" v-if="value.isNumber">

              </a>
              <p v-else v-html="value.text">
              </p>
            </template>
          </div>
        </div>
      </div>
      <div class="bottom-overlay-nav">
        <div class="soc-link">
          <a v-if="settings.instagram" :href="settings.instagram"># instagram</a>
        </div>
        <div class="develop-by" v-if="settings && settings.watter_mark">
          <a href="#">
            <div class="name"><img src="/images/developedby-dark.svg" alt="uxlove studio" class="developedby"></div>
            <img src="/images/uxlove-logo-dark.svg" alt="uxlove studio"></a>
        </div>
      </div>
    </div>
    <div class="open-nav"></div>
    <div class="fixed-holder-btn" v-if="isDemo">
      <a href="https://t.me/uxwebbot?start=demo" class="fixed-btn infinite animated pulse">
        <span class="btn-text">{{$static('demo_btn_create.create', 'Створити безкоштовно')}}</span>
      </a>
    </div>

    <Busked></Busked>
    <MenuHeader/>
    <Categories></Categories>

    <router-view/>
  </main>
  <footer>
    <div class="footer-holder">
      <div class="footer-contacts">
        <div class="holder">
          <h5>{{$static('footer.tot-contacts', 'Контакти')}}</h5>
          <div class="contact-box">
            <div class="contact-item" v-if="settings.email">
              <div class="name">{{$static('footer.email', 'Электронна пошта')}}:</div>
              <a :href="'mailto:'+settings.email">{{settings.email}}</a>
            </div>
            <div class="contact-item" v-if="settings.phone">
              <div class="name">{{$static('footer.phone', 'Телефон')}}:</div>
              <!--              <a :href="'tel:'+settings.phone">{{settings.phone}}</a>-->
              <template v-for="(value,key) in phones" :key="key">
                <a :href="'tel:'+value.real" v-html="value.text" v-if="value.isNumber">

                </a>
                <p v-else v-html="value.text">
                </p>
              </template>

            </div>

            <div class="contact-item" v-if="settings.instagram || settings.telegram">
              <div class="name">{{$static('footer.social', 'Соціальні мережі')}}:</div>
              <a v-if="settings.instagram" :href="settings.instagram">#instagram</a>
              <a v-if="settings.telegram" :href="settings.telegram">@telegram</a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-links">
        <div class="holder">
          <ul>
            <li>
              <router-link :to="{name:'about'}">
                <span class="text">{{$static('menu.about','Про нас')}}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{name:'delivery'}">
                <span class="text">{{$static('menu.delivery','Доставка та оплата')}}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{name:'policy'}">
                <span class="text">{{$static('menu.policy','Політика конфіденційності')}}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{name:'dostup'}">
                <span class="text">{{$static('menu.dostup','Політика доступності')}}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{name:'offerta'}">
                <span class="text">{{$static('menu.offerta','Публічна оферта')}}</span>
              </router-link>
            </li>
          </ul>
          <div class="logos">
            <img src="/images/icons/mastercard.svg" alt="mastercard">
            <img src="/images/icons/visa.svg" alt="visa">
          </div>
        </div>
      </div>
      <div class="copyright">
        <div class="copy">
          &copy; {{$static('footer.copy', `2023 Копіювання матеріалів сайту без дозволу заборонено`)}}
        </div>

        <div class="develop-by"  v-if="settings && settings.watter_mark">

          <a href="https://ux.im/">
            <div class="name"><img src="/images/developedby.svg" alt="uxlove studio" class="developedby"></div>
            <img src="/images/uxlove-logo.svg" alt="uxlove studio"></a>
        </div>
      </div>
    </div>
  </footer>

  <div class="modal custom-modal fade" id="consult_modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <img src="/images/icons/close.svg" alt="">
          </button>
        </div>
        <div class="modal-body">
          <h2>{{$static('modal-callback.title', 'Отримати консультацію')}}</h2>
          <p>{{$static('modal-callback.text', `Введіть свій номер телефону нижче,
            і наш менеджер зв’яжеться з Вами
            за декілька хвилин`)}}</p>
          <form>
            <div class="form-group" :class="{'error': errors['phone']}">
              <input type="text" class="form-control custom-input" @focus="delError('phone')" placeholder="+380998787858"
                     v-model="callbackPhone">
              <p class="help-text" v-for="(err,key) in errors['phone']" :key="key">{{err}}</p>
            </div>
            <div class="button-holder">
              <a href="javascript:void(0)" class="default-btn" @click="sendCallback">
                <span class="btn-text">{{$static('modal-callback.button', 'Отримати консультацію')}}</span>
                <img src="/images/icons/send-light.svg" alt="">
              </a>
            </div>
          </form>
          <div class="attention-apply">{{$static('modal-callback.info1', 'При кліці на кнопку я погоджуюся з')}}
            <router-link :to="{name: 'policy'}">{{$static('modal-callback.info2', `політикою обробки персональних даних`)}}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Busked from './components/Busked';
  import Categories from './components/Categories';
  import MenuHeader from './components/MenuHeader';

  export default {
    components: {MenuHeader, Categories, Busked},
    data() {
      return {
        callbackPhone: '',
        errors       : {},
        isDemo: false
      }
    },
    methods   : {
      delError(key) {
        delete this.errors[key]
      },
      validate() {
        this.errors = {};
        if (this.callbackPhone.length < 8) {
          this.errors.phone = [this.$static('order.phone-error', 'Введіть коректний номер')];
          return false
        }
        return true;
      },
      // sendCallback() {
      //   let valid = this.validate();
      //   if (!valid) {
      //     return
      //   }
      //   if (window.Telegram) {
      //     return fetch(
      //       '/public/callback',
      //       {
      //         method : 'POST',
      //         body   : JSON.stringify({phone: this.callbackPhone}),
      //         headers: {initData: window.Telegram ? window.Telegram.WebApp.initData : ''}
      //       }
      //     ).then(response => response.json()).then(() => {
      //       // setInterval(() => {
      //       window.Telegram.WebApp.close();
      //       // }, 1000);
      //
      //       // if (data.result) {
      //       //   return data.result
      //       // }
      //       return []
      //     })
      //   }
      // },
      fetchDeliveryOptions() {
        return fetch('/public/delivery').then(response => response.json()).then(
          data => {
            if (data) {
              this.$store.commit('setDeliveryOptions', data)
            }
          })
      },
      fetchPaymentOptions() {
        return fetch('/public/payment').then(response => response.json()).then(
          data => {
            if (data) {
              this.$store.commit('setPayment', data)
            }
          })
      },
      fetchSettings() {
        return fetch('/public/settings').then(response => response.json()).then(
          data => {
            if (data) {
              this.$store.commit('setSettings', data);
              // let check = ['code1', 'code2', 'code3'];
              // check.forEach(x => {
              //   if (data[x]) {
              //     let elem           = document.createElement('div');
              //     elem.style.display = 'none';
              //     elem.innerHTML     = data[x];
              //
              //     // let newHTML = document.querySelector('head').innerHTML;
              //     // newHTML += data[x];
              //     // document.querySelector('head').innerHTML = newHTML;
              //
              //     document.querySelector('head').appendChild(elem)
              //   }
              // });
            }

          })
      }

    },
    mounted() {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop)
      });
      if (params.code) {
        this.$Code(params.code)
      }

      window.pos    = {top: 0, left: 0, x: 0, y: 0};
      window.ele    = undefined;
      window.change = false;
      this.isDemo = location.hostname === "demo.ux.im";

      function isTouchDevice() {
        return (('ontouchstart' in window) ||
          (navigator.maxTouchPoints > 0) ||
          (navigator.msMaxTouchPoints > 0));
      }

      window.istouch          = isTouchDevice();
      window.mouseDownHandler = function (e) {
        window.pos = {
          // The current scroll
          left: window.ele.scrollLeft,
          top : window.ele.scrollTop,
          // Get the current mouse position
          x   : e.clientX,
          y   : e.clientY
        };

        // Change the cursor and prevent user from selecting the text
        // ele.style.cursor = 'grabbing';

        if (!window.istouch) {
          window.ele.style.userSelect = 'none';
          window.change               = false;
          document.addEventListener('mousemove', window.mouseMoveHandler);
          document.addEventListener('mouseup', window.mouseUpHandler);
        }
      };

      window.mouseMoveHandler = function (e) {
        // console.log('mouseMoveHandler', e)
        // How far the mouse has been moved
        window.change         = true;
        const dx              = e.clientX - window.pos.x;
        const dy              = e.clientY - window.pos.y;
        // Scroll the element
        window.ele.scrollTop  = window.pos.top - dy;
        window.ele.scrollLeft = window.pos.left - dx;
      };

      window.clearChange    = function (e) {
        window.change = false;
      }
      window.mouseUpHandler = function () {
        // console.log('mouseUpHandler')
        document.removeEventListener('mousemove', window.mouseMoveHandler);
        document.removeEventListener('mouseup', window.mouseUpHandler);
        setTimeout(window.clearChange, 300)
      };

      document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

      if (window.Telegram) {
        window.Telegram.WebApp.ready();
        window.Telegram.WebApp.expand();
        window.Telegram.WebApp.enableClosingConfirmation();
        // document.getElementById('subtitle').innerText = navigator.userAgent;
        if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
          window.document.addEventListener('touchmove', function (e) {
            if (e.scale !== 1) {
              e.preventDefault();
            }
          }, {passive: false});
          window.Telegram.WebApp.onEvent('viewportChanged', window.Telegram.WebApp.expand)
        }
        if (window.Telegram.WebApp.initData && window.Telegram.WebApp.initData.length) {
          localStorage.setItem('initData', window.Telegram.WebApp.initData)
        }
      }

      (function ($) {
        'use strict';
        $('#category_panel').stick_in_parent({
                                               offset_top: 0
                                             });

        $('.show-menu-btn').on('click', function () {
          $('.overlay-menu').addClass('active');
          $('body').addClass('no-scroll');
          $('.open-nav').addClass('is-visible');
          return false;
        });
        $('.overlay-menu .close-menu-btn').on('click', function () {
          $('.overlay-menu').removeClass('active');
          $('body').removeClass('no-scroll');
          $('.open-nav').removeClass('is-visible');
          return false;
        });
        $('.open-nav').on('click', function () {
          $('.overlay-menu').removeClass('active');
          $('body').removeClass('no-scroll');
          $(this).removeClass('is-visible');
          return false;
        });
        $(function () {
          if ($(window).scrollTop() >= 100) {
            $('.fixed-holder-btn').addClass('stickybottom');
          }
          $(window).scroll(function () {
            if ($(this).scrollTop() >= 100) {
              $('.fixed-holder-btn').addClass('stickybottom');
            } else {
              $('.fixed-holder-btn').removeClass('stickybottom');
            }
          });
        });

        $(function () {
          if ($(window).scrollTop() >= 300) {
            $('.fixed-options-panel').addClass('stickybottom');
          }
          $(window).scroll(function () {
            if ($(this).scrollTop() >= 300) {
              $('.fixed-options-panel').addClass('stickybottom');
            } else {
              $('.fixed-options-panel').removeClass('stickybottom');
            }
          });
        });
      })(window.$);

      //-- hotfix
      let assets = {
        'icon-menu-1': 'icon-pills',
        'icon-menu-2': 'icon-mortartboard'
      };
      //--

      let options = axios.get('/public/options').then(response => response.data).then(data => {
        data.result.forEach(o => {
          /*eslint no-case-declarations: "off"*/
          switch (o.option_id) {
          case 'tags':
            this.$store.commit('setTags', o.list);
            break;
          case 'category':
            this.$store.commit('setCategories', o.list);
            break;
          default:
            this.$store.commit('setOptions', o);
            break;
          }
        });

        if (data.settings) {
          this.$store.commit('setOptionSettings', data.settings);
        }
      });

      // options = axios.get('/public/options/categories').then(response => response.data).then(data => {
      //   data.result.forEach(o => {
      //
      //     /*eslint no-case-declarations: "off"*/
      //     if (o.option_id === 'category') { this.$store.commit('setCategories', o.list); }
      //   })
      // });

      // let land = fetch('/storage/landing.json').then(response => response.json()).then(data => {
      //   if (data) {
      //     this.$store.commit('setLanding', data);
      //   }
      // });

      let delivery = this.fetchDeliveryOptions();
      let payments = this.fetchPaymentOptions();
      let settings = this.fetchSettings();

      Promise.all([options, delivery, payments]).then(() => {
        window.$('.preloader-page').removeClass('proccess-load')
      })
    },
    computed  : {
      settings() {
        return this.$store.getters.getSettings
      },

      logo() {
        return this.settings && this.settings.logo ? this.settings.logo : ''
      },
      initData() {
        return window.location.href
      },
      s_info() {
        return this.settings && this.settings.s_info ? this.settings.s_info : false
      },
      phones() {
        return this.settings && this.settings.phone ?
          this.settings.phone.split(',').map(x => x.trim())
            .map(x => {return {real: x.replace(/^\D+/g, ''), text: x, isNumber: x.startsWith('+')}}) : false
      },

      local() {
        return this.$local()
      },
      dynamics() {
        return this.$dynamics()
      }
    }

  }
</script>
